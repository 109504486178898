export default {
  message: {
    hello: '你好 世界'
  },
  button: {
    add: '按钮',
    cklj: '查看了解',
    ckgd: '查看更多',
    ckxq: '查看详情',
    ckqb: '查看全部',
    lxwm: '联系我们',
    fhml: '返回目录',
    jrwm: '加入我们',
    xjzx: '询价中心'
  },
  gdsj: {
    tit: '高端试剂',
    contone: '基于全球科技前沿技术，开发供应多品类特色试剂',
    conttow: '基于核心技术的精细化学品生产',
    contthree: '特色试剂：联烯'
  },
  dzhc: {
    tit: '定制合成',
    contone: '面向市场需求的试剂定制',
    conttow: '面向市场需求的试剂定制',
    contthree: '定制规模：毫克到公斤级'
  },
  yhjs: {
    tit: '核心技术',
    contone: '技术研发领域：新型工业催化技术、药物发现与 药物递送技术、碳中和与清洁技术发展等领域',
    conttow: '技术开发团队：来自复旦大学、北京大学、中国科 学技术大学、浙江大学、华东师范大学等优秀团队',
    contthree: '特色技术：联烯化学、清洁氧化'
  },
  aboutus: {
    address: '地址',
    phone: '电话',
    email: '邮箱',
    fixPhone: '固话'
  },
  honor: {
    clgs: '成立公司',
    sjcg: '多个试剂开发成功',
    hdry: '获得荣誉',
    fmzl: '发明专利',
    hxjs: '核心技术'
  },
  development: {
    tit: '发展历程',
    contentone: '安徽英纳威迅科技有限公司创建于2022年，是由合肥溪轩科技有限公司与浙江新和成股份有限公司共同发起设立的创新研发型公司。',
    contenttow: '公司依托高校或研究所原创研发团队与新和成产业平台，发展功能分子产业链关键核心技术，为精细化工、生物医药、新能源、新材料等行业提供先进技术与产品。'
  },
  productdetail: {
    productName: '产品名称',
    zhName: '中文名称',
    casNo: 'CAS号',
    chemicalFormula: '分子式',
    molecularWeight: '分子量',
    mdlNo: 'MDL NO',
    boilingPoint: '沸点',
    pubchemId: 'Pubchem ID',
    inChIKey: 'InChIKey',
    inItemNo: '货号',
    titone: '产品信息',
    tittow: '联烯产品其他推荐',
    titthree: '明星产品其他推荐',
    titFour: '丙炔醇产品其他推荐',
    titFive: '硼酸/硼酸脂产品其他推荐',
    titSix: '配体产品其他推荐',
  },
  joinus: {
    zpgs: '招聘公司',
    zpzw: '招聘职位',
    gzdd: '工作地点',
    fbrq: '发布日期'
  }
}
