import Vue from 'vue'
import VueRouter from 'vue-router'
// import homePage from '@/views/homePage/index'

Vue.use(VueRouter)

// 解决vue路由重复导航错误
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // {
  //   path: '/',
  //   name: 'homePage',
  //   meta: {
  //     roles: ['*']
  //   },
  //   component: homePage,
  //   children: [
  {
    path: '/',
    redirect: '/homePage'
  },
  {
    path: '/homePage',
    name: 'HomePage',
    component: () => import('@/views/homePage'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/aboutUs'),
    meta: {
      title: '走进我们'
    },
    // redirect: '/company',
    children: [
      {
        path: '/aboutUs/company',
        name: 'Company',
        // component: () => import('@/views/aboutUs/company'),
        redirect: '/aboutUs',
        meta: {
          title: '公司简介'
        }
      },
      {
        path: '/aboutUs/culture',
        name: 'Culture',
        // component: () => import('@/views/aboutUs/culture'),
        redirect: '/aboutUs',
        meta: {
          title: '公司文化'
        }
      },
      {
        path: '/aboutUs/development',
        name: 'Development',
        // component: () => import('@/views/aboutUs/development'),
        redirect: '/aboutUs',
        meta: {
          title: '公司发展'
        }
      },
      {
        path: '/aboutUs/team',
        name: 'Team',
        // component: () => import('@/views/aboutUs/team'),
        redirect: '/aboutUs',
        meta: {
          title: '管理团队'
        }
      },
      {
        path: '/aboutUs/platform',
        name: 'Platform',
        // component: () => import('@/views/aboutUs/platform'),
        redirect: '/aboutUs',
        meta: {
          title: '职业提升平台'
        }
      },
      {
        path: '/aboutUs/certificate',
        name: 'Certificate',
        // component: () => import('@/views/aboutUs/certificate'),
        redirect: '/aboutUs',
        meta: {
          title: '资质荣誉'
        }
      },
      {
        path: '/aboutUs/technology',
        name: 'Technology',
        // component: () => import('@/views/aboutUs/technology'),
        redirect: '/aboutUs',
        meta: {
          title: '技术与团队'
        }
      }
    ]
  },
  {
    path: '/aboutUs/team/detail',
    name: 'TeamDetail',
    component: () => import('@/views/aboutUs/team/detail'),
    meta: {
      title: '管理团队详情'
    }
  },
  {
    path: '/serviceCenter/kit',
    name: 'ServiceKit',
    // redirect: '/serviceCenter',
    component: () => import('@/views/serviceCenter/kit'),
    meta: {
      title: '服务中心高端试剂'
    }
  },
  {
    path: '/serviceCenter/product',
    name: 'ServiceProduct',
    // redirect: '/serviceCenter',
    // component: () => import('@/views/serviceCenter/product'),
    component: () => import('@/views/serviceCenter/kit'),
    meta: {
      title: '服务中心定制合成'
    }
  },
  {
    path: '/serviceCenter/technology',
    name: 'ServiceTechnology',
    // redirect: '/serviceCenter',
    // component: () => import('@/views/serviceCenter/technology'),
    component: () => import('@/views/serviceCenter/kit'),
    meta: {
      title: '服务中心硬核技术'
    }
  },
  {
    path: '/serviceCenter/price',
    name: 'ProductPrice',
    // component: () => import('@/views/serviceCenter/productPrice'),
    component: () => import('@/views/serviceCenter/kit'),
    meta: {
      title: '服务中心询价中心'
    }
  },
  {
		path: '/productCenter',
		name: 'productCenter',
		component: () => import('@/views/productCenter/product'),
		meta: {
			title: '产品中心'
		},
		children: [
			{
				path: '/productCenter/bialene',
				name: 'ProductBialene',
				redirect: '/productCenter',
				meta: {
					title: '联烯产品'
				}
			},
			{
				path: '/productCenter/intermediate',
				name: 'ProductIntermediate',
				redirect: '/productCenter',
				meta: {
					title: '明星产品'
				}
			},
			{
				path: '/productCenter/propargylAlcohol',
				name: 'ProductPropargylAlcohol',
				redirect: '/productCenter',
				meta: {
					title: '丙炔醇产品'
				}
			},
			{
				path: '/productCenter/boricAcid',
				name: 'ProductBoricAcid',
				redirect: '/productCenter',
				meta: {
					title: '硼酸/硼酸脂产品'
				}
			},
			{
				path: '/productCenter/ligand',
				name: 'ProductLigand',
				redirect: '/productCenter',
				meta: {
					title: '配体产品'
				}
			},
		]
  },
	{
	  path: '/productCenter/list',
	  name: 'ProductList',
	  component: () => import('@/views/productCenter/productList'),
	  meta: {
	    title: '产品中心搜索列表'
	  }
	},
	{
		path: '/productCenter/detail',
		name: 'ProductDetail',
		component: () => import('@/views/productCenter/detail'),
		meta: {
			title: '产品详情'
		}
	},
  {
    path: '/newsCenter/news',
    name: 'NewsCenternews',
    component: () => import('@/views/newsCenter/news'),
    meta: {
      title: '新闻中心公司新闻'
    }
  },
  {
    path: '/newsCenter/news/detail',
    name: 'NewsNewsdetail',
    component: () => import('@/views/newsCenter/Newsdetail'),
    meta: {
      title: '新闻中心公司新闻详情'
    }
  },
  {
    path: '/newsCenter/life',
    name: 'NewsCenterlife',
    // component: () => import('@/views/newsCenter/life'),
    component: () => import('@/views/newsCenter/news'),
    meta: {
      title: '新闻中心文化生活'
    }
  },
  {
    path: '/newsCenter/life/detail',
    name: 'NewsLifedetail',
    component: () => import('@/views/newsCenter/Lifedetail'),
    meta: {
      title: '新闻中心文化生活详情'
    }
  },
  {
    path: '/joinUs',
    name: 'JoinUs',
    component: () => import('@/views/joinUs'),
    meta: {
      title: '加入我们'
    }
  },
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: () => import('@/views/contactUs'),
    meta: {
      title: '联系我们'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
